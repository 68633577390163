// build configs ------------------------------------------------------
const SERVICE_URL = process.env.GATSBY_SERVICE_URL;

export const serviceUrl = `${SERVICE_URL}`;

export const grapqhURL = `${SERVICE_URL}/graphql`;

export const apiURL = `${SERVICE_URL}/api`;

export const mapBoxToken = process.env.MAPBOX_TOKEN;
