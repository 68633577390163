import * as React from "react";

export const MapIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2703_3385)">
      <path
        d="M8.83325 13V26.3334L14.6666 23L21.3333 26.3334L27.1666 23V9.66669L21.3333 13L14.6666 9.66669L8.83325 13Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 9.66669V23"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 13V26.3333"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2703_3385">
        <rect width="20" height="20" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export const TaskIcon: React.FC<{ color?: string }> = ({ color = "black" }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15C8.71667 15 8.47933 14.904 8.288 14.712C8.096 14.5207 8 14.2833 8 14C8 13.7167 8.096 13.479 8.288 13.287C8.47933 13.0957 8.71667 13 9 13H16C16.2833 13 16.521 13.0957 16.713 13.287C16.9043 13.479 17 13.7167 17 14C17 14.2833 16.9043 14.5207 16.713 14.712C16.521 14.904 16.2833 15 16 15H9ZM9 23C8.71667 23 8.47933 22.904 8.288 22.712C8.096 22.5207 8 22.2833 8 22C8 21.7167 8.096 21.479 8.288 21.287C8.47933 21.0957 8.71667 21 9 21H16C16.2833 21 16.521 21.0957 16.713 21.287C16.9043 21.479 17 21.7167 17 22C17 22.2833 16.9043 22.5207 16.713 22.712C16.521 22.904 16.2833 23 16 23H9ZM21.675 16.3L19.525 14.15C19.3417 13.9667 19.25 13.7333 19.25 13.45C19.25 13.1667 19.3417 12.9333 19.525 12.75C19.7083 12.5667 19.9417 12.475 20.225 12.475C20.5083 12.475 20.7417 12.5667 20.925 12.75L22.35 14.175L25.9 10.625C26.1 10.425 26.3333 10.329 26.6 10.337C26.8667 10.3457 27.1 10.45 27.3 10.65C27.4833 10.85 27.575 11.0833 27.575 11.35C27.575 11.6167 27.4833 11.85 27.3 12.05L23.075 16.3C22.875 16.5 22.6417 16.6 22.375 16.6C22.1083 16.6 21.875 16.5 21.675 16.3ZM21.675 24.3L19.525 22.15C19.3417 21.9667 19.25 21.7333 19.25 21.45C19.25 21.1667 19.3417 20.9333 19.525 20.75C19.7083 20.5667 19.9417 20.475 20.225 20.475C20.5083 20.475 20.7417 20.5667 20.925 20.75L22.35 22.175L25.9 18.625C26.1 18.425 26.3333 18.329 26.6 18.337C26.8667 18.3457 27.1 18.45 27.3 18.65C27.4833 18.85 27.575 19.0833 27.575 19.35C27.575 19.6167 27.4833 19.85 27.3 20.05L23.075 24.3C22.875 24.5 22.6417 24.6 22.375 24.6C22.1083 24.6 21.875 24.5 21.675 24.3Z"
      fill={color}
    />
  </svg>
);

export const ExploreIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 8.83331L9.75 12.5V25.3333C9.75 25.8195 9.94315 26.2859 10.287 26.6297C10.6308 26.9735 11.0971 27.1666 11.5833 27.1666H24.4167C24.9029 27.1666 25.3692 26.9735 25.713 26.6297C26.0568 26.2859 26.25 25.8195 26.25 25.3333V12.5L23.5 8.83331H12.5Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 12.5H26.25"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6666 16.1667C21.6666 17.1391 21.2803 18.0718 20.5926 18.7594C19.905 19.447 18.9724 19.8334 17.9999 19.8334C17.0275 19.8334 16.0948 19.447 15.4072 18.7594C14.7196 18.0718 14.3333 17.1391 14.3333 16.1667"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MoreIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1667 9.75H9.75V16.1667H16.1667V9.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 9.75H19.8334V16.1667H26.25V9.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 19.8333H19.8334V26.25H26.25V19.8333Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1667 19.8333H9.75V26.25H16.1667V19.8333Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Notif: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2703_3923)">
      <path
        d="M20.1429 18.6429H19.5V9.53571C19.5 5.75625 16.7063 2.63304 13.0714 2.11339V1.07143C13.0714 0.479464 12.592 0 12 0C11.408 0 10.9286 0.479464 10.9286 1.07143V2.11339C7.29375 2.63304 4.5 5.75625 4.5 9.53571V18.6429H3.85714C3.38304 18.6429 3 19.0259 3 19.5V20.3571C3 20.475 3.09643 20.5714 3.21429 20.5714H9C9 22.2268 10.3446 23.5714 12 23.5714C13.6554 23.5714 15 22.2268 15 20.5714H20.7857C20.9036 20.5714 21 20.475 21 20.3571V19.5C21 19.0259 20.617 18.6429 20.1429 18.6429ZM12 21.8571C11.2902 21.8571 10.7143 21.2812 10.7143 20.5714H13.2857C13.2857 21.2812 12.7098 21.8571 12 21.8571ZM6.42857 18.6429V9.53571C6.42857 8.04643 7.00714 6.64821 8.05982 5.59554C9.1125 4.54286 10.5107 3.96429 12 3.96429C13.4893 3.96429 14.8875 4.54286 15.9402 5.59554C16.9929 6.64821 17.5714 8.04643 17.5714 9.53571V18.6429H6.42857Z"
        fill="#B4B4B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2703_3923">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const LogoRect: React.FC = () => (
  <svg
    width="94"
    height="20"
    viewBox="0 0 94 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3619_13831)">
      <path
        d="M89.7313 4.48317L90.881 1.01758H91.4751L92.6193 4.48317H92.1792L91.1066 1.26512H91.2386L90.1714 4.48317H89.7313ZM90.2814 3.66903V3.26746H92.0637V3.66903H90.2814Z"
        fill="#0A41F2"
      />
      <path
        d="M93.5766 4.48317V1.01758H94.0001V4.48317H93.5766Z"
        fill="#0A41F2"
      />
      <path
        d="M26.4046 16.921V2.82764H28.1319V9.0437H35.4921V2.82764H37.2139V16.921H35.4921V10.6995H28.1484V16.921H26.4046Z"
        fill="#0A41F2"
      />
      <path
        d="M40.1239 4.48313V2.68433H41.8457V4.48313H40.1239ZM40.1239 16.9207V6.35345H41.8457V16.9207H40.1239Z"
        fill="#0A41F2"
      />
      <path
        d="M58.2661 17.2181C57.315 17.2354 56.3779 16.9878 55.5596 16.5029C54.7936 16.0431 54.1737 15.3755 53.7718 14.5776C53.3308 13.678 53.112 12.6858 53.1337 11.6841C53.1065 10.649 53.3232 9.622 53.7663 8.68611C54.1642 7.88078 54.7871 7.20816 55.5596 6.74978C56.3536 6.27592 57.2645 6.03391 58.189 6.05116C59.1392 6.023 60.076 6.27972 60.879 6.78829C61.6288 7.30353 62.2043 8.03444 62.5293 8.88415C62.9202 9.91521 63.0834 11.0186 63.0079 12.1187H61.2806V11.5026C61.3407 10.4713 61.0646 9.44824 60.4939 8.5871C60.2235 8.25956 59.8787 8.00133 59.4884 7.83389C59.098 7.66645 58.6733 7.59464 58.2496 7.62443C57.7912 7.59517 57.3323 7.67401 56.91 7.8546C56.4877 8.03519 56.1137 8.31247 55.8181 8.66411C55.2085 9.52978 54.9134 10.578 54.982 11.6346C54.9174 12.6685 55.2129 13.6929 55.8181 14.5336C56.1052 14.8788 56.4692 15.1517 56.8811 15.3304C57.2929 15.509 57.7409 15.5885 58.189 15.5623C58.8238 15.5778 59.4498 15.4118 59.9934 15.0837C60.5172 14.7479 60.9334 14.2686 61.1926 13.703L62.8428 14.2806C62.4684 15.1734 61.829 15.9299 61.011 16.4479C60.1882 16.9612 59.2358 17.2285 58.2661 17.2181ZM54.4154 12.1242V10.727H62.1607V12.1022L54.4154 12.1242Z"
        fill="#0A41F2"
      />
      <path
        d="M65.4612 16.9208V6.35351H67.007V8.90595L66.754 8.57589C66.8713 8.25462 67.0323 7.95105 67.2325 7.67374C67.4155 7.41297 67.6404 7.18433 67.8982 6.99712C68.1715 6.77458 68.4827 6.60314 68.8168 6.49104C69.1543 6.37385 69.5057 6.30172 69.862 6.2765C70.1977 6.25382 70.5349 6.27976 70.8632 6.35351V8.00379C70.4739 7.90203 70.0686 7.87589 69.6695 7.92678C69.2331 7.97229 68.818 8.13796 68.4703 8.40536C68.1445 8.63344 67.8769 8.93494 67.6891 9.28551C67.5036 9.62968 67.3737 10.001 67.3041 10.3857C67.2331 10.7907 67.1981 11.2012 67.1995 11.6124V16.9153L65.4612 16.9208Z"
        fill="#0A41F2"
      />
      <path
        d="M72.7169 16.9209V6.35364H74.2626V8.90608L74.0151 8.57602C74.1324 8.25475 74.2935 7.95118 74.4937 7.67387C74.6762 7.4146 74.8991 7.18618 75.1538 6.99725C75.4298 6.77571 75.7426 6.60441 76.0779 6.49117C76.4152 6.37348 76.7667 6.30133 77.1231 6.27663C77.457 6.25371 77.7924 6.27965 78.1188 6.35364V8.00392C77.7313 7.90205 77.3279 7.8759 76.9306 7.92691C76.4926 7.97228 76.0756 8.13791 75.7259 8.40549C75.4017 8.63376 75.1359 8.93531 74.9502 9.28564C74.7648 9.62981 74.6348 10.0011 74.5652 10.3858C74.4942 10.7908 74.4592 11.2014 74.4607 11.6125V16.9154L72.7169 16.9209Z"
        fill="#0A41F2"
      />
      <path
        d="M82.7892 17.218C82.1078 17.2409 81.4319 17.0894 80.8254 16.7779C80.3254 16.5172 79.911 16.1181 79.6317 15.6282C79.3624 15.152 79.2239 14.613 79.2301 14.066C79.2177 13.5656 79.3252 13.0695 79.5437 12.6192C79.754 12.2129 80.0598 11.8637 80.4348 11.6016C80.8509 11.3144 81.312 11.0987 81.7991 10.9634C82.3422 10.8143 82.8931 10.6949 83.4493 10.6059C84.0654 10.5014 84.6871 10.4134 85.3252 10.3253L87.0965 10.0833L86.4749 10.4409C86.5543 9.70189 86.3584 8.95959 85.9248 8.356C85.6557 8.11246 85.3383 7.9283 84.9933 7.81551C84.6483 7.70273 84.2834 7.66384 83.9224 7.70139C83.3425 7.68914 82.7708 7.83969 82.2721 8.13597C81.7692 8.46224 81.4093 8.9677 81.2655 9.54971L79.6152 9.04912C79.8281 8.16603 80.3464 7.38665 81.0784 6.84875C81.9213 6.28202 82.9244 6.00229 83.9389 6.05111C84.7796 6.04101 85.6108 6.22948 86.3648 6.60121C87.0274 6.92167 87.5498 7.47343 87.8336 8.15247C87.9857 8.48821 88.0806 8.84707 88.1141 9.21415C88.1526 9.61271 88.1709 10.013 88.1691 10.4134V16.921H86.6399V14.385L86.9974 14.6216C86.6558 15.419 86.0804 16.0941 85.3472 16.5579C84.5731 17.013 83.6869 17.2417 82.7892 17.218ZM83.0478 15.7548C83.6052 15.7649 84.1571 15.6443 84.6595 15.4027C85.0921 15.1976 85.4693 14.892 85.7597 14.5116C86.0353 14.1589 86.2238 13.7461 86.3098 13.3068C86.4116 12.9488 86.4652 12.5788 86.4694 12.2067C86.4694 11.8033 86.4694 11.4952 86.4694 11.2825L87.069 11.5465L85.4187 11.7721C84.8686 11.8436 84.3625 11.9206 83.8784 12.0031C83.4372 12.0749 83.0002 12.1704 82.5692 12.2892C82.2918 12.3784 82.0244 12.4964 81.7716 12.6412C81.5299 12.7752 81.3228 12.9635 81.1665 13.1913C81.0096 13.4413 80.931 13.7325 80.9409 14.0275C80.9416 14.3152 81.0174 14.5977 81.161 14.8471C81.3178 15.1225 81.5535 15.3447 81.8376 15.4852C82.2176 15.6758 82.6393 15.7685 83.0643 15.7548H83.0478Z"
        fill="#0A41F2"
      />
      <path
        d="M48.3478 4.48314H43.5895V2.68434H46.9176C47.1051 2.68361 47.291 2.71993 47.4645 2.79121C47.638 2.86248 47.7957 2.96731 47.9286 3.09968C48.0615 3.23206 48.1669 3.38936 48.2388 3.56258C48.3108 3.7358 48.3478 3.92152 48.3478 4.10908V4.48314Z"
        fill="#0A41F2"
      />
      <path
        d="M54.8334 4.48314H50.0586V4.10908C50.0586 3.92152 50.0956 3.7358 50.1675 3.56258C50.2395 3.38936 50.3449 3.23206 50.4778 3.09968C50.6107 2.96731 50.7684 2.86248 50.9419 2.79121C51.1154 2.71993 51.3012 2.68361 51.4888 2.68434H54.8169L54.8334 4.48314Z"
        fill="#0A41F2"
      />
      <path
        d="M50.0751 4.48315H48.3478V16.9208H50.0751V4.48315Z"
        fill="#0A41F2"
      />
      <path
        d="M15.7107 0H3.96067C1.77325 0 0 1.77325 0 3.96067V15.7107C0 17.8981 1.77325 19.6713 3.96067 19.6713H15.7107C17.8981 19.6713 19.6713 17.8981 19.6713 15.7107V3.96067C19.6713 1.77325 17.8981 0 15.7107 0Z"
        fill="#C0FF40"
      />
      <path
        d="M6.41943 8.71899H13.2516L9.83551 14.1814L6.41943 8.71899Z"
        fill="#0A41F2"
      />
      <path
        d="M7.70126 7.2998C7.70126 7.2998 5.14333 7.78939 5.0058 8.59803C4.90679 9.19213 5.94646 10.1823 9.85213 10.1823C13.7578 10.1823 14.803 9.20313 14.6985 8.61453C14.5884 7.80039 12.0305 7.2998 12.0305 7.2998"
        stroke="white"
        strokeWidth="0.478581"
        strokeMiterlimit="10"
      />
      <path
        d="M13.2516 8.71902H6.41943L9.83551 3.25659L13.2516 8.71902Z"
        fill="#0A41F2"
      />
      <path
        d="M12.4374 16.9209H7.22253V19.6714H12.4374V16.9209Z"
        fill="#0A41F2"
      />
      <path
        d="M12.4432 16.9208H19.4789C19.6078 16.5304 19.6728 16.1217 19.6714 15.7106V13.0261H16.3379C15.3049 13.0261 14.3143 13.4365 13.5839 14.1668C12.8535 14.8972 12.4432 15.8879 12.4432 16.9208Z"
        fill="#0A41F2"
      />
      <path
        d="M3.33909 13.0261H2.23681e-05V15.7106C-0.00138501 16.1217 0.06363 16.5304 0.192555 16.9208H7.23375C7.23375 15.8879 6.82342 14.8972 6.09303 14.1668C5.36264 13.4365 4.37202 13.0261 3.33909 13.0261Z"
        fill="#0A41F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_3619_13831">
        <rect width="94" height="19.6713" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InfoIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.9043 16.5207 13 16.2833 13 16C13 15.7167 12.9043 15.479 12.713 15.287C12.521 15.0957 12.2833 15 12 15C11.7167 15 11.4793 15.0957 11.288 15.287C11.096 15.479 11 15.7167 11 16C11 16.2833 11.096 16.5207 11.288 16.712C11.4793 16.904 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
      fill="#989898"
    />
  </svg>
);

export const InterestIcon: React.FC = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_10423)">
      <path
        d="M8.516 12.6999C6.748 12.6999 5.316 14.1319 5.316 15.8999C5.316 17.6679 6.748 19.0999 8.516 19.0999C10.284 19.0999 11.716 17.6679 11.716 15.8999C11.716 14.1319 10.284 12.6999 8.516 12.6999ZM8.516 17.4999C7.636 17.4999 6.916 16.7799 6.916 15.8999C6.916 15.0199 7.636 14.2999 8.516 14.2999C9.396 14.2999 10.116 15.0199 10.116 15.8999C10.116 16.7799 9.396 17.4999 8.516 17.4999ZM13.3 12.6999V19.0999H19.7V12.6999H13.3ZM18.1 17.4999H14.9V14.2999H18.1V17.4999ZM8.5 3.8999L4.5 11.0999H12.5L8.5 3.8999ZM8.5 7.1959L9.78 9.4999H7.22L8.5 7.1959ZM18.3 4.2999C17.452 4.2999 16.852 4.7479 16.5 5.2359C16.148 4.7479 15.548 4.2999 14.7 4.2999C13.452 4.2999 12.5 5.3239 12.5 6.4999C12.5 8.0999 14.436 9.2359 16.5 11.0999C18.564 9.2359 20.5 8.0999 20.5 6.4999C20.5 5.3239 19.548 4.2999 18.3 4.2999ZM16.5 8.9799C15.34 8.0039 14.1 7.0599 14.1 6.4999C14.1 6.1559 14.38 5.8999 14.7 5.8999C14.948 5.8999 15.116 6.0359 15.284 6.1959L16.5 7.3399L17.716 6.1959C17.884 6.0359 18.052 5.8999 18.3 5.8999C18.62 5.8999 18.9 6.1559 18.9 6.4999C18.9 7.0599 17.66 8.0039 16.5 8.9799Z"
        fill="#0AC787"
      />
    </g>
    <defs>
      <clipPath id="clip0_2594_10423">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const CrownIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_10423)">
      <path
        d="M21.2228 6.05944L16.7202 8.83367L12.4026 3.07466C12.3853 3.05148 12.3627 3.03266 12.3368 3.01971C12.3109 3.00675 12.2824 3 12.2534 3C12.2244 3 12.1959 3.00675 12.17 3.01971C12.1441 3.03266 12.1215 3.05148 12.1042 3.07466L7.78895 8.83367L3.28401 6.05944C3.15213 5.97846 2.98091 6.08721 3.00173 6.24223L4.76946 19.6761C4.79492 19.8589 4.95225 20 5.13967 20H19.3718C19.5569 20 19.7165 19.8612 19.7397 19.6761L21.5074 6.24223C21.5259 6.08721 21.357 5.97846 21.2228 6.05944ZM18.3074 18.4174H6.19938L4.95457 8.94473L8.18924 10.9369L12.2546 5.51339L16.3199 10.9369L19.5546 8.94473L18.3074 18.4174ZM12.2546 11.439C10.8177 11.439 9.64924 12.6075 9.64924 14.0443C9.64924 15.4812 10.8177 16.6496 12.2546 16.6496C13.6914 16.6496 14.8599 15.4812 14.8599 14.0443C14.8599 12.6075 13.6914 11.439 12.2546 11.439ZM12.2546 15.1619C11.6391 15.1619 11.1393 14.6621 11.1393 14.0443C11.1393 13.4288 11.6391 12.9268 12.2546 12.9268C12.87 12.9268 13.3698 13.4265 13.3698 14.0443C13.3698 14.6598 12.87 15.1619 12.2546 15.1619"
        fill="#0AC787"
      />
    </g>
  </svg>
);

export const BlackCrownIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2594_10423)">
      <path
        d="M21.2228 6.05944L16.7202 8.83367L12.4026 3.07466C12.3853 3.05148 12.3627 3.03266 12.3368 3.01971C12.3109 3.00675 12.2824 3 12.2534 3C12.2244 3 12.1959 3.00675 12.17 3.01971C12.1441 3.03266 12.1215 3.05148 12.1042 3.07466L7.78895 8.83367L3.28401 6.05944C3.15213 5.97846 2.98091 6.08721 3.00173 6.24223L4.76946 19.6761C4.79492 19.8589 4.95225 20 5.13967 20H19.3718C19.5569 20 19.7165 19.8612 19.7397 19.6761L21.5074 6.24223C21.5259 6.08721 21.357 5.97846 21.2228 6.05944ZM18.3074 18.4174H6.19938L4.95457 8.94473L8.18924 10.9369L12.2546 5.51339L16.3199 10.9369L19.5546 8.94473L18.3074 18.4174ZM12.2546 11.439C10.8177 11.439 9.64924 12.6075 9.64924 14.0443C9.64924 15.4812 10.8177 16.6496 12.2546 16.6496C13.6914 16.6496 14.8599 15.4812 14.8599 14.0443C14.8599 12.6075 13.6914 11.439 12.2546 11.439ZM12.2546 15.1619C11.6391 15.1619 11.1393 14.6621 11.1393 14.0443C11.1393 13.4288 11.6391 12.9268 12.2546 12.9268C12.87 12.9268 13.3698 13.4265 13.3698 14.0443C13.3698 14.6598 12.87 15.1619 12.2546 15.1619"
        fill="black"
      />
    </g>
  </svg>
);

export const ArrowRightIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12H19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5L19 12L12 19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReportIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2171_3013)">
      <path
        d="M13.0498 20.96H19.9798V22.94H13.0498V20.96ZM13.0498 17H22.9498V18.98H13.0498V17ZM13.0498 13.04H22.9498V15.02H13.0498V13.04ZM24.9298 9.07998H20.7916C20.3758 7.93158 19.2868 7.09998 17.9998 7.09998C16.7128 7.09998 15.6238 7.93158 15.208 9.07998H11.0698C10.9312 9.07998 10.8025 9.08988 10.6738 9.11958C10.2877 9.19878 9.94124 9.39678 9.67394 9.66408C9.49574 9.84228 9.34724 10.0601 9.24824 10.2977C9.14924 10.5254 9.08984 10.7828 9.08984 11.06V24.92C9.08984 25.1873 9.14924 25.4546 9.24824 25.6922C9.34724 25.9298 9.49574 26.1377 9.67394 26.3258C9.94124 26.5931 10.2877 26.7911 10.6738 26.8703C10.8025 26.8901 10.9312 26.9 11.0698 26.9H24.9298C26.0188 26.9 26.9098 26.009 26.9098 24.92V11.06C26.9098 9.97098 26.0188 9.07998 24.9298 9.07998ZM17.9998 8.83248C18.4057 8.83248 18.7423 9.16908 18.7423 9.57498C18.7423 9.98088 18.4057 10.3175 17.9998 10.3175C17.5939 10.3175 17.2573 9.98088 17.2573 9.57498C17.2573 9.16908 17.5939 8.83248 17.9998 8.83248ZM24.9298 24.92H11.0698V11.06H24.9298V24.92Z"
        fill="#0AC787"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_3013">
        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const RightArrowIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2171_3052)">
      <path
        d="M14.5898 22.59L19.1698 18L14.5898 13.41L15.9998 12L21.9998 18L15.9998 24L14.5898 22.59Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_3052">
        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const EditIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4ZM14.325 9.675L13.625 8.975L15.025 10.375L14.325 9.675Z"
      fill="#0AC787"
    />
  </svg>
);

export const MarketIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18018 10.0402H8.17918C9.37798 10.0402 10.4103 10.906 10.5768 12.1048L12.1086 21.7618H21.8655C25.4952 21.7618 28.4256 18.8314 28.4256 15.2017V13.0372L10.71 13.0705"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.6726 13.0704L18.3023 8.97453C19.268 7.87563 20.9663 7.80903 21.9986 8.87463L26.1611 13.0371"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.1085 21.7616L11.4092 25.9574C11.1428 27.6224 13.2074 28.6547 14.3729 27.4226L19.7009 21.7616"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.6295 21.1624C26.3611 21.3622 27.7264 22.8274 27.7264 24.6256C27.7264 26.557 26.1613 28.1221 24.2299 28.1221C22.2985 28.1221 20.7334 26.557 20.7334 24.6256C20.7334 23.4268 21.3328 22.3945 22.2319 21.7618"
      stroke="black"
      strokeWidth="1.998"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const FieldIcon: React.FC<{ color?: string }> = ({
  color = "#0AC787",
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6345 6.07805C16.3763 6.07805 17.0281 6.61751 17.163 7.35927L18.8488 17.0021C18.9162 17.3842 18.7364 17.6764 18.6465 17.7888C18.5341 17.9237 18.2868 18.126 17.9047 18.126H6.26138C5.85678 18.126 5.63201 17.9012 5.51962 17.7888C5.40723 17.654 5.22742 17.3842 5.31733 17.0021L7.00314 7.35927C7.138 6.61751 7.76737 6.07805 8.53161 6.07805H15.6345ZM15.6345 4.7294H8.48665C7.09304 4.7294 5.87926 5.74089 5.63201 7.11202L3.9462 16.7549C3.69894 18.1709 4.80034 19.4746 6.2389 19.4746H17.8822C19.3208 19.4746 20.4222 18.1709 20.1749 16.7549L18.4891 7.11202C18.2419 5.74089 17.0281 4.7294 15.6345 4.7294Z"
      fill={color}
    />
    <path
      d="M11.9929 4.95425V19.4747"
      stroke={color}
      strokeWidth="1.34865"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.99146 9.53967H18.1293"
      stroke={color}
      strokeWidth="1.26593"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.22754 13.7429H18.9613"
      stroke={color}
      strokeWidth="1.26593"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FieldIconHome: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6345 6.07805C16.3763 6.07805 17.0281 6.61751 17.163 7.35927L18.8488 17.0021C18.9162 17.3842 18.7364 17.6764 18.6465 17.7888C18.5341 17.9237 18.2868 18.126 17.9047 18.126H6.26138C5.85678 18.126 5.63201 17.9012 5.51962 17.7888C5.40723 17.654 5.22742 17.3842 5.31733 17.0021L7.00314 7.35927C7.138 6.61751 7.76737 6.07805 8.53161 6.07805H15.6345ZM15.6345 4.7294H8.48665C7.09304 4.7294 5.87926 5.74089 5.63201 7.11202L3.9462 16.7549C3.69894 18.1709 4.80034 19.4746 6.2389 19.4746H17.8822C19.3208 19.4746 20.4222 18.1709 20.1749 16.7549L18.4891 7.11202C18.2419 5.74089 17.0281 4.7294 15.6345 4.7294Z"
      fill="#E2FFF5"
    />
    <path
      d="M11.9929 4.95425V19.4747"
      stroke="#E2FFF5"
      strokeWidth="1.34865"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.99146 9.53967H18.1293"
      stroke="#E2FFF5"
      strokeWidth="1.26593"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.22754 13.7429H18.9613"
      stroke="#E2FFF5"
      strokeWidth="1.26593"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GrayEditIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2204 5.65143H5.67631C5.2562 5.65143 4.8533 5.81831 4.55624 6.11537C4.25917 6.41243 4.09229 6.81533 4.09229 7.23543V18.3234C4.09229 18.7435 4.25917 19.1464 4.55624 19.4435C4.8533 19.7405 5.2562 19.9074 5.67631 19.9074H16.7645C17.1846 19.9074 17.5875 19.7405 17.8846 19.4435C18.1817 19.1464 18.3485 18.7435 18.3485 18.3234V12.7794"
      stroke="#989898"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1604 4.4634C17.4755 4.14832 17.9028 3.97131 18.3484 3.97131C18.794 3.97131 19.2213 4.14832 19.5364 4.4634C19.8515 4.77848 20.0285 5.20581 20.0285 5.6514C20.0285 6.09699 19.8515 6.52432 19.5364 6.8394L12.0123 14.3634L8.84424 15.1554L9.63625 11.9874L17.1604 4.4634Z"
      stroke="#989898"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfileIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 26V24C26 22.9391 25.5786 21.9217 24.8284 21.1716C24.0783 20.4214 23.0609 20 22 20H14C12.9391 20 11.9217 20.4214 11.1716 21.1716C10.4214 21.9217 10 22.9391 10 24V26"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 16C20.2091 16 22 14.2091 22 12C22 9.79086 20.2091 8 18 8C15.7909 8 14 9.79086 14 12C14 14.2091 15.7909 16 18 16Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddContactIcon: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2171_3015)">
      <path
        d="M21 18C23.21 18 25 16.21 25 14C25 11.79 23.21 10 21 10C18.79 10 17 11.79 17 14C17 16.21 18.79 18 21 18ZM21 12C22.1 12 23 12.9 23 14C23 15.1 22.1 16 21 16C19.9 16 19 15.1 19 14C19 12.9 19.9 12 21 12ZM21 20C18.33 20 13 21.34 13 24V26H29V24C29 21.34 23.67 20 21 20ZM15 24C15.22 23.28 18.31 22 21 22C23.7 22 26.8 23.29 27 24H15ZM12 21V18H15V16H12V13H10V16H7V18H10V21H12Z"
        fill="gray"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_3015">
        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const CalendarIcon: React.FC<{ color?: string }> = ({
  color = "#0AC787",
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.04 6.23999H6.96002C6.16473 6.23999 5.52002 6.8847 5.52002 7.67999V17.76C5.52002 18.5553 6.16473 19.2 6.96002 19.2H17.04C17.8353 19.2 18.48 18.5553 18.48 17.76V7.67999C18.48 6.8847 17.8353 6.23999 17.04 6.23999Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8804 4.79999V7.67999"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.11963 4.79999V7.67999"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.52002 10.56H18.48"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckBoxIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6 16.2L17.65 9.15L16.25 7.75L10.6 13.4L7.75 10.55L6.35 11.95L10.6 16.2ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
      fill="black"
    />
  </svg>
);

export const SowingIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3958 6L20.0238 7.924C21.0228 9.108 21.5408 10.625 21.4668 12.142L21.3188 15.25C21.2448 16.915 19.8758 18.21 18.2108 18.21H17.8038C16.1018 18.21 14.6958 16.841 14.6958 15.102V12.327"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.658 17.359L19.21 21.244C20.505 22.502 20.505 24.574 19.247 25.869C17.952 27.201 15.843 27.201 14.548 25.906L8.517 20.578C7.777 19.838 7 18.876 7 17.84V8.62695"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.9439 21.984C26.6591 21.984 27.2389 21.4043 27.2389 20.689C27.2389 19.9738 26.6591 19.394 25.9439 19.394C25.2287 19.394 24.6489 19.9738 24.6489 20.689C24.6489 21.4043 25.2287 21.984 25.9439 21.984Z"
      fill={color}
    />
    <path
      d="M26.6842 28.866C27.3994 28.866 27.9792 28.2862 27.9792 27.571C27.9792 26.8558 27.3994 26.276 26.6842 26.276C25.969 26.276 25.3892 26.8558 25.3892 27.571C25.3892 28.2862 25.969 28.866 26.6842 28.866Z"
      fill={color}
    />
    <path
      d="M29.295 24.59C30.0102 24.59 30.59 24.0102 30.59 23.295C30.59 22.5798 30.0102 22 29.295 22C28.5798 22 28 22.5798 28 23.295C28 24.0102 28.5798 24.59 29.295 24.59Z"
      fill={color}
    />
  </svg>
);

export const EditBoxIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2204 5.65143H5.67631C5.2562 5.65143 4.8533 5.81831 4.55624 6.11537C4.25917 6.41243 4.09229 6.81533 4.09229 7.23543V18.3234C4.09229 18.7435 4.25917 19.1464 4.55624 19.4435C4.8533 19.7405 5.2562 19.9074 5.67631 19.9074H16.7645C17.1846 19.9074 17.5875 19.7405 17.8846 19.4435C18.1817 19.1464 18.3485 18.7435 18.3485 18.3234V12.7794"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1604 4.4634C17.4755 4.14832 17.9028 3.97131 18.3484 3.97131C18.794 3.97131 19.2213 4.14832 19.5364 4.4634C19.8515 4.77848 20.0285 5.20581 20.0285 5.6514C20.0285 6.09699 19.8515 6.52432 19.5364 6.8394L12.0123 14.3634L8.84424 15.1554L9.63625 11.9874L17.1604 4.4634Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddIcon: React.FC<{ color?: string }> = ({ color = "black" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2171_3002)">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.40002V15.6"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3999 12H15.5999"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_3002">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CopyIcon: React.FC<{ color?: string }> = ({ color = "black" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4798 9.57001H11.1898C10.2951 9.57001 9.56982 10.2953 9.56982 11.19V18.48C9.56982 19.3747 10.2951 20.1 11.1898 20.1H18.4798C19.3745 20.1 20.0998 19.3747 20.0998 18.48V11.19C20.0998 10.2953 19.3745 9.57001 18.4798 9.57001Z"
      stroke={color}
      strokeWidth="1.62"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.3299 14.43H5.5199C5.09025 14.43 4.6782 14.2593 4.37439 13.9555C4.07058 13.6517 3.8999 13.2397 3.8999 12.81V5.52002C3.8999 5.09037 4.07058 4.67832 4.37439 4.37451C4.6782 4.0707 5.09025 3.90002 5.5199 3.90002H12.8099C13.2396 3.90002 13.6516 4.0707 13.9554 4.37451C14.2592 4.67832 14.4299 5.09037 14.4299 5.52002V6.33002"
      stroke={color}
      strokeWidth="1.62"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TrashIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.8999 6.59998H5.6999H20.0999"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3002 6.6V19.2C18.3002 19.6774 18.1106 20.1352 17.773 20.4728C17.4354 20.8104 16.9776 21 16.5002 21H7.5002C7.02281 21 6.56497 20.8104 6.2274 20.4728C5.88984 20.1352 5.7002 19.6774 5.7002 19.2V6.6M8.4002 6.6V4.8C8.4002 4.32261 8.58984 3.86477 8.9274 3.52721C9.26497 3.18964 9.72281 3 10.2002 3H13.8002C14.2776 3 14.7354 3.18964 15.073 3.52721C15.4106 3.86477 15.6002 4.32261 15.6002 4.8V6.6"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2002 11.1V16.5"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7998 11.1V16.5"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ConsumptionIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8755 8.11182H8.99341V28.1251H26.8755V8.11182Z"
      stroke={color}
      strokeWidth="1.97975"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1488 17.4692V18.2684C20.1488 20.2664 18.5171 21.8981 16.5191 21.8981H15.7199V21.0989C15.7199 19.1009 17.3516 17.4692 19.3496 17.4692H20.1488ZM22.1468 15.4712H19.3496C16.2527 15.4712 13.7219 18.002 13.7219 21.0989V23.8961H16.5191C19.616 23.8961 22.1468 21.3653 22.1468 18.2684V15.4712Z"
      fill={color}
    />
    <path
      d="M9.85913 12.2411H26.3759"
      stroke={color}
      strokeWidth="2.08967"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.7219 11.7083V8.51147"
      stroke={color}
      strokeWidth="1.332"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9512 11.7083V8.51147"
      stroke={color}
      strokeWidth="1.332"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.1472 11.7083V8.51147"
      stroke={color}
      strokeWidth="1.332"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlantIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8189 27.9336V12.4491C17.8189 9.51872 15.4546 7.15442 12.5241 7.15442H8.09521V9.81842C8.09521 12.216 10.0266 14.1474 12.4242 14.1474H17.8189"
      stroke={color}
      strokeWidth="1.87037"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.4849 20.9073H22.6474C25.5112 20.9073 27.8089 18.6096 27.8089 15.7458V14.1807H22.8472C20.4496 14.1807 18.4849 16.1454 18.4849 18.543V20.9073Z"
      stroke={color}
      strokeWidth="1.87037"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.2225 20.3079C14.8885 20.3079 16.2871 20.3079 16.2871 23.0385V23.4048H14.722C13.39 23.4048 12.4576 23.0052 11.8582 22.1727C11.4919 21.7065 11.2588 21.0405 11.1256 20.3079H14.2225ZM14.2225 18.4431H9.09424V19.0092C9.09424 22.1061 10.4928 25.2696 14.6887 25.2696H18.1519V23.0385C18.1519 20.874 17.3194 18.4431 14.2225 18.4431Z"
      fill={color}
    />
  </svg>
);

export const CycleIcon: React.FC<{ color?: string }> = ({
  color = "black",
}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.725 27.125C12.9917 26.325 11.579 25.1167 10.487 23.5C9.39567 21.8833 8.85 20.0583 8.85 18.025C8.85 17.5917 8.875 17.1667 8.925 16.75C8.975 16.3333 9.05 15.925 9.15 15.525L8 16.2L7 14.475L11.775 11.725L14.525 16.475L12.775 17.475L11.425 15.125C11.2417 15.575 11.1043 16.0417 11.013 16.525C10.921 17.0083 10.875 17.5083 10.875 18.025C10.875 19.6417 11.3167 21.1127 12.2 22.438C13.0833 23.7627 14.2583 24.7417 15.725 25.375L14.725 27.125ZM22.375 15V13H25.1C24.3333 12.05 23.4083 11.3123 22.325 10.787C21.2417 10.2623 20.0917 10 18.875 10C17.9583 10 17.0917 10.1417 16.275 10.425C15.4583 10.7083 14.7083 11.1083 14.025 11.625L13.025 9.875C13.8583 9.29167 14.7627 8.83333 15.738 8.5C16.7127 8.16667 17.75 8 18.85 8C20.1667 8 21.429 8.24567 22.637 8.737C23.8457 9.229 24.925 9.94167 25.875 10.875V9.5H27.875V15H22.375ZM21.725 30L16.95 27.25L19.7 22.5L21.425 23.5L20 25.95C21.9667 25.6667 23.6043 24.779 24.913 23.287C26.221 21.7957 26.875 20.0417 26.875 18.025C26.875 17.8417 26.871 17.6667 26.863 17.5C26.8543 17.3333 26.8333 17.1667 26.8 17H28.825C28.8417 17.1667 28.8543 17.3293 28.863 17.488C28.871 17.646 28.875 17.8167 28.875 18C28.875 20.25 28.2043 22.2627 26.863 24.038C25.521 25.8127 23.775 27.0083 21.625 27.625L22.725 28.275L21.725 30Z"
      fill={color}
    />
  </svg>
);

export const NoteIcon: React.FC<{ color?: string }> = ({ color = "black" }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 24H22V22H14V24ZM14 20H22V18H14V20ZM12 28C11.45 28 10.9793 27.8043 10.588 27.413C10.196 27.021 10 26.55 10 26V10C10 9.45 10.196 8.979 10.588 8.587C10.9793 8.19567 11.45 8 12 8H20L26 14V26C26 26.55 25.8043 27.021 25.413 27.413C25.021 27.8043 24.55 28 24 28H12ZM19 15V10H12V26H24V15H19Z"
      fill={color}
    />
  </svg>
);

export const LeafIcon: React.FC<{ color?: string }> = ({ color = "black" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4 24.5923C11.65 23.8423 11.0627 22.9756 10.638 21.9923C10.2127 21.009 10 19.9923 10 18.9423C10 17.8923 10.2 16.8546 10.6 15.8293C11 14.8046 11.65 13.8423 12.55 12.9423C13.1333 12.359 13.8543 11.859 14.713 11.4423C15.571 11.0256 16.5873 10.6963 17.762 10.4543C18.9373 10.213 20.2793 10.0673 21.788 10.0173C23.296 9.96731 24.9833 10.0256 26.85 10.1923C26.9833 11.959 27.025 13.584 26.975 15.0673C26.925 16.5506 26.7873 17.888 26.562 19.0793C26.3373 20.2713 26.021 21.313 25.613 22.2043C25.2043 23.0963 24.7 23.8423 24.1 24.4423C23.2167 25.3256 22.2793 25.9713 21.288 26.3793C20.296 26.788 19.2833 26.9923 18.25 26.9923C17.1667 26.9923 16.1083 26.78 15.075 26.3553C14.0417 25.93 13.15 25.3423 12.4 24.5923ZM15.2 24.1923C15.6833 24.4756 16.1793 24.6796 16.688 24.8043C17.196 24.9296 17.7167 24.9923 18.25 24.9923C19.0167 24.9923 19.775 24.8383 20.525 24.5303C21.275 24.2216 21.9917 23.7256 22.675 23.0423C22.975 22.7423 23.279 22.3216 23.587 21.7803C23.8957 21.2383 24.1627 20.5296 24.388 19.6543C24.6127 18.7796 24.7833 17.7216 24.9 16.4803C25.0167 15.2383 25.0333 13.759 24.95 12.0423C24.1333 12.009 23.2127 11.9963 22.188 12.0043C21.1627 12.013 20.1417 12.0923 19.125 12.2423C18.1083 12.3923 17.1417 12.634 16.225 12.9673C15.3083 13.3006 14.5583 13.759 13.975 14.3423C13.225 15.0923 12.7083 15.834 12.425 16.5673C12.1417 17.3006 12 18.009 12 18.6923C12 19.6756 12.1873 20.538 12.562 21.2793C12.9373 22.0213 13.2667 22.5423 13.55 22.8423C14.25 21.509 15.175 20.2296 16.325 19.0043C17.475 17.7796 18.8167 16.7756 20.35 15.9923C19.15 17.0423 18.1043 18.2296 17.213 19.5543C16.321 20.8796 15.65 22.4256 15.2 24.1923Z"
      fill={color}
    />
  </svg>
);
